@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #da291c;
  /* Red color */
  --secondary-color: rgb(0, 0, 0);
  /* Black color */
  --text-white: rgb(255, 255, 255);
  /* White color */
  --text-black: rgb(0, 0, 0);
  /* Black color */
  --background-color-white: rgb(245, 245, 245);
  /* Light grey color */
  --background-color-black: rgb(0, 0, 0);
  --background-color-nav-gray: rgb(36, 36, 36);
  /* Dark grey color */
}

/* @font-face {
  font-family: "Creolia";
  src: url("./assets/fonts/Creolia.ttf") format("truetype"),
    url("./assets/fonts/Creolia.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* * {
  font-family: "Creolia";
  src: url("./assets/fonts/Creolia.ttf") format("truetype"),
    url("./assets/fonts/Creolia.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
} */

/* Custom styles for the input fields */
.custom-input {
  border: none;
  border-bottom: 2px solid #555; /* Adjust color as needed */
  padding: 0.5rem;
  width: 100%;
  outline: none;
  transition: border-color 0.3s;
  border-left: 2px solid #555; /* Add left border */
}

.custom-input::placeholder {
  color: #999; /* Placeholder color */
}

.custom-input:focus {
  border-color: #333; /* Color on focus */
}

.PhoneInputInput {
  outline: none !important;
}